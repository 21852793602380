import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDSnackbar from "components/MDSnackbar"; // Import MDSnackbar
import validations from "layouts/pages/users/new-user/schemas/validations";
import ProductInfo from "layouts/ecommerce/products/new-product/components/ProductInfo";
import AddProjectService from "services/AddProjectService"; // Adjust path if needed
import { useLocation, useNavigate } from "react-router-dom";
import ClientAddService from "services/ClientAddService";

function NewProject() {
  const navigate = useNavigate();
  const location = useLocation();
  const projectId = location.state ? location.state.projectId : null;
  const id = location.state ? location.state.clientId : 0;
  const [isEditMode, setIsEditMode] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [values, setValues] = useState({
    projectName: "",
    projectId: "",
    clientName: "",
    files: [{ fileName: "", fileComments: "" }],
    credentials: [{ credName: "", environment: "", username: "", password: "", credComments: "" }],
    projectUrls: [{ name: "", URL: "", projectComments: "" }],
  });
  const [errors, setErrors] = useState({});
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  useEffect(() => {
    if (projectId) {
      setIsEditMode(true);
      fetchProjectData(projectId);
    }
    fetchClientList();
  }, [projectId]);

  const fetchProjectData = async (id) => {
    try {
      const response = await AddProjectService.getById(id);
      if (response && response.data) {
        setValues({
          projectName: response.data.projectName || "",
          projectId: response.data.projectId || "",
          clientName: response.data.clientName || "",
          files: response.data.files || [{ fileName: "", fileComments: "" }],
          credentials: response.data.credentials || [
            { credName: "", environment: "", username: "", password: "", credComments: "" },
          ],
          projectUrls: response.data.projectUrls || [{ name: "", URL: "", projectComments: "" }],
        });
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
      openErrorSB(); // Display error snackbar on data fetch failure
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name]; // Delete error for the specific field
        return updatedErrors;
      });
    }
  };

  const handleFileChange = (newFiles) => {
    setValues((prevValues) => ({
      ...prevValues,
      files: newFiles,
    }));
  };

  const handleCredentialChange = (newCredentials) => {
    setValues((prevValues) => ({
      ...prevValues,
      credentials: newCredentials,
    }));
  };

  const handleProjectUrlChange = (newProjectUrls) => {
    setValues((prevValues) => ({
      ...prevValues,
      projectUrls: newProjectUrls,
    }));
  };

  const handleClear = () => {
    setValues({
      projectName: "",
      projectId: "",
      files: [{ fileName: "", fileComments: "" }],
      credentials: [
        { credName: "", environment: "", username: "", password: "", credComments: "" },
      ],
      projectUrls: [{ name: "", URL: "", projectComments: "" }],
    });
    setErrors({});
  };

  const validateForm = () => {
    let formErrors = {};
    if (!values.projectName) formErrors.projectName = "Project Name is required";
    if (!values.projectId) formErrors.projectId = "Project ID is required";

    values.files.forEach((file, index) => {
      if (!file.fileName) formErrors[`fileName-${index}`] = "File is required";
      if (!file.fileComments) formErrors[`fileComments-${index}`] = "File Comments are required";
    });

    values.credentials.forEach((credential, index) => {
      if (!credential.credName) formErrors[`credName-${index}`] = "Credential Name is required";
      if (!credential.environment)
        formErrors[`environment-${index}`] = "Environment Name is required";
      if (!credential.username) formErrors[`username-${index}`] = "Username is required";
      if (!credential.password) formErrors[`password-${index}`] = "Password is required";
      if (!credential.credComments)
        formErrors[`credComments-${index}`] = "Credential Comments are required";
    });

    values.projectUrls.forEach((url, index) => {
      if (!url.name) formErrors[`urlName-${index}`] = "URL Name is required";
      if (!url.URL) formErrors[`url-${index}`] = "URL is required";
      if (!url.projectComments)
        formErrors[`projectComments-${index}`] = "Project Comments are required";
    });

    return formErrors;
  };

  const handleSubmit = async () => {
    const dataToSubmit = { ...values, clientName: values.clientName };
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      openErrorSB(); // Show error snackbar if there are form validation errors
      return;
    }

    try {
      if (isEditMode) {
        await AddProjectService.update(projectId, dataToSubmit); // Update if in edit mode
        openSuccessSB(); // Show success snackbar
        setTimeout(() => {
          navigate("/projects/list-project");
        }, 1000); // Delay navigation until after snackbar is shown
      } else {
        await AddProjectService.add(dataToSubmit); // Add if in create mode
        openSuccessSB(); // Show success snackbar
        setTimeout(() => {
          navigate("/projects/list-project");
        }, 1000); // Delay navigation until after snackbar is shown
      }
    } catch (error) {
      console.error(isEditMode ? "Error updating project:" : "Error adding project:", error);
      openErrorSB(); // Show error snackbar if submission fails
    }
  };

  const fetchClientList = async () => {
    try {
      const response = await ClientAddService.getAll();
      const client = response.data;

      const clientOptions = client.map((client) => ({
        value: client.clientName,
        label: client.clientName,
        id: client._id,
        type: "client",
      }));

      setClientList([...clientOptions]);
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={1} mb={3}>
        <Grid container justifyContent="center" sx={{ height: "100%", mt: 4 }}>
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox p={2}>
                <ProductInfo
                  values={values}
                  handleChange={handleChange}
                  handleFileChange={handleFileChange}
                  handleCredentialChange={handleCredentialChange}
                  handleProjectUrlChange={handleProjectUrlChange}
                  clientList={clientList}
                  errors={errors}
                  isEditMode={isEditMode}
                />
                <MDBox py={3} px={3} width="100%" display="flex" justifyContent="end" gap="10px">
                  <MDButton variant="gradient" color="light" onClick={handleClear}>
                    Clear
                  </MDButton>
                  <MDButton variant="gradient" color="dark" onClick={handleSubmit}>
                    {isEditMode ? "Update" : "Submit"}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Success Snackbar */}
      <MDSnackbar
        color="success"
        icon="check"
        title="Success"
        content={isEditMode ? "Project updated successfully!" : "Project added successfully!"}
        dateTime="justnow"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bggreen="true"
        sx={{
          position: "fixed",
          top: -500,
          right: 20,
          zIndex: 9999,
        }}
      />

      {/* Error Snackbar */}
      <MDSnackbar
        color="error"
        icon="warning"
        title="Error"
        content="Please fill all required fields."
        dateTime="justnow"
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgred="true"
        sx={{
          position: "fixed",
          top: -500,
          right: 20,
          zIndex: 9999,
        }}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default NewProject;
