import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import { Autocomplete } from "@mui/material";

function ProductInfo({
  values,
  errors,
  handleChange,
  handleFileChange,
  handleCredentialChange,
  handleProjectUrlChange,
  clientList,
  isEditMode,
  touched,
}) {
  const addRowFileRef = () => {
    const newFiles = [...values.files, { fileName: "", comments: "" }];
    handleFileChange(newFiles);
  };

  const removeRowFileRef = (index) => {
    const updatedFiles = values.files.filter((_, i) => i !== index);
    handleFileChange(updatedFiles);
  };

  const addRowCredential = () => {
    const newCredentials = [
      ...values.credentials,
      { credName: "", environment: "", username: "", password: "", comments: "" },
    ];

    handleCredentialChange(newCredentials);
  };

  const removeRowCredential = (index) => {
    const updatedCredentials = values.credentials.filter((_, i) => i !== index);
    handleCredentialChange(updatedCredentials);
  };

  const addRowProjectUrl = () => {
    const newProjectUrls = [...values.projectUrls, { name: "", URL: "", comments: "" }];
    handleProjectUrlChange(newProjectUrls);
  };

  const removeRowProjectUrl = (index) => {
    const updatedProjectUrls = values.projectUrls.filter((_, i) => i !== index);
    handleProjectUrlChange(updatedProjectUrls);
  };

  return (
    <MDBox xs={12} sm={6} md={6} lg={4} alignItems="center" sx={{ height: "100%", py: 2, px: 2 }}>
      <MDTypography variant="h5">
        {isEditMode ? "Edit Project Information" : "Add Project Information"}
      </MDTypography>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} sm={4} mt={1}>
          <MDInput
            fullWidth
            type="text"
            label="Project Name"
            name="projectName"
            onChange={handleChange}
            value={values.projectName}
            error={false}
            helperText={
              errors.projectName && <span style={{ color: "red" }}>{errors.projectName}</span>
            }
            success={values.projectName.length > 0 && !errors.projectName}
          />
        </Grid>
        <Grid item xs={12} sm={4} mt={1}>
          <MDInput
            fullWidth
            type="text"
            label="Project Id"
            name="projectId"
            onChange={handleChange}
            value={values.projectId}
            error={false}
            helperText={
              errors.projectId && <span style={{ color: "red" }}>{errors.projectId}</span>
            }
            success={values.projectId.length > 0 && !errors.projectId}
          />
        </Grid>
        <Grid item xs={12} sm={4} paddingBottom={3} mt={1}>
          <Autocomplete
            fullWidth
            options={clientList.map((client) => client.label)} // Assuming label is the client name
            getOptionLabel={(option) => option}
            value={values.clientName || null}
            onChange={(event, newValue) =>
              handleChange({ target: { name: "clientName", value: newValue } })
            }
            renderInput={(params) => (
              <MDInput {...params} fullWidth label="Select Client Name" variant="outlined" />
            )}
            sx={{
              ".MuiOutlinedInput-root": {
                height: "45px", // Matches MDInput height
              },
            }}
          />
        </Grid>
      </Grid>

      {/* File Reference Section */}
      <MDTypography variant="h5" paddingTop={3}>
        File Reference
      </MDTypography>
      {values.files.map((file, index) => (
        <Grid container spacing={1} key={index} mt={1}>
          <Grid item xs={12} sm={5}>
            <MDInput
              type="file"
              fullWidth
              onChange={(e) => {
                const selectedFile = e.target.files[0];
                if (selectedFile) {
                  handleFileChange(
                    values.files.map((f, i) =>
                      i === index ? { ...f, fileName: selectedFile.name } : f
                    )
                  );
                }
              }}
              error={false}
              helperText={
                errors[`fileName-${index}`] && (
                  <span style={{ color: "red" }}>{errors[`fileName-${index}`]}</span>
                )
              }
              success={Boolean(values.files[index].fileName)}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <MDInput
              fullWidth
              type="text"
              label="Comments"
              value={file.fileComments}
              onChange={(e) =>
                handleFileChange(
                  values.files.map((f, i) =>
                    i === index ? { ...f, fileComments: e.target.value } : f
                  )
                )
              }
              error={false}
              helperText={
                errors[`fileComments-${index}`] && (
                  <span style={{ color: "red" }}>{errors[`fileComments-${index}`]}</span>
                )
              }
              success={Boolean(file.fileComments)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <MDButton
              variant="gradient"
              color="dark"
              style={{ minWidth: "50px", padding: "10px" }}
              onClick={addRowFileRef}
              iconOnly
            >
              <AddIcon />
            </MDButton>
            {index > 0 && (
              <MDButton
                variant="gradient"
                color="light"
                style={{ minWidth: "50px", marginLeft: "10px" }}
                onClick={() => removeRowFileRef(index)}
                iconOnly
              >
                <RemoveIcon />
              </MDButton>
            )}
          </Grid>
        </Grid>
      ))}

      {/* Credentials Section */}
      <MDTypography variant="h5" paddingTop={3}>
        Credentials
      </MDTypography>
      {values.credentials.map((credential, index) => (
        <Grid container spacing={1} key={index} mt={1}>
          <Grid item xs={12} sm={2}>
            <MDInput
              fullWidth
              label="Name"
              type="text"
              value={credential.credName}
              onChange={(e) =>
                handleCredentialChange(
                  values.credentials.map((c, i) =>
                    i === index ? { ...c, credName: e.target.value } : c
                  )
                )
              }
              error={false}
              helperText={
                errors[`credName-${index}`] && (
                  <span style={{ color: "red" }}>{errors[`credName-${index}`]}</span>
                )
              }
              success={Boolean(credential.credName)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <MDInput
              fullWidth
              label="Environment"
              type="text"
              value={credential.environment}
              onChange={(e) =>
                handleCredentialChange(
                  values.credentials.map((c, i) =>
                    i === index ? { ...c, environment: e.target.value } : c
                  )
                )
              }
              error={false}
              helperText={
                errors[`environment-${index}`] && (
                  <span style={{ color: "red" }}>{errors[`environment-${index}`]}</span>
                )
              }
              success={Boolean(credential.environment)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <MDInput
              fullWidth
              label="Username"
              type="text"
              value={credential.username}
              onChange={(e) =>
                handleCredentialChange(
                  values.credentials.map((c, i) =>
                    i === index ? { ...c, username: e.target.value } : c
                  )
                )
              }
              error={false}
              helperText={
                errors[`username-${index}`] && (
                  <span style={{ color: "red" }}>{errors[`username-${index}`]}</span>
                )
              }
              success={Boolean(credential.username)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <MDInput
              fullWidth
              label="Password"
              type="text"
              value={credential.password}
              onChange={(e) =>
                handleCredentialChange(
                  values.credentials.map((c, i) =>
                    i === index ? { ...c, password: e.target.value } : c
                  )
                )
              }
              error={false}
              helperText={
                errors[`password-${index}`] && (
                  <span style={{ color: "red" }}>{errors[`password-${index}`]}</span>
                )
              }
              success={Boolean(credential.password)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <MDInput
              fullWidth
              label="Comments"
              type="text"
              value={credential.credComments}
              onChange={(e) =>
                handleCredentialChange(
                  values.credentials.map((c, i) =>
                    i === index ? { ...c, credComments: e.target.value } : c
                  )
                )
              }
              error={false}
              helperText={
                errors[`credComments-${index}`] && (
                  <span style={{ color: "red" }}>{errors[`credComments-${index}`]}</span>
                )
              }
              success={Boolean(credential.credComments)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <MDButton
              variant="gradient"
              color="dark"
              style={{ minWidth: "50px", padding: "5px" }}
              onClick={addRowCredential}
              iconOnly
            >
              <AddIcon />
            </MDButton>
            {index > 0 && (
              <MDButton
                variant="gradient"
                color="light"
                style={{ minWidth: "50px", marginLeft: "10px" }}
                onClick={() => removeRowCredential(index)}
                iconOnly
              >
                <RemoveIcon />
              </MDButton>
            )}
          </Grid>
        </Grid>
      ))}

      {/* Project URLs Section */}
      <MDTypography variant="h5" paddingTop={3}>
        Project URLs
      </MDTypography>
      {values.projectUrls.map((url, index) => (
        <Grid container spacing={1} key={index} mt={1}>
          <Grid item xs={12} sm={3}>
            <MDInput
              fullWidth
              label="URL Name"
              type="text"
              value={url.name}
              onChange={(e) =>
                handleProjectUrlChange(
                  values.projectUrls.map((u, i) =>
                    i === index ? { ...u, name: e.target.value } : u
                  )
                )
              }
              error={false}
              helperText={
                errors[`urlName-${index}`] && (
                  <span style={{ color: "red" }}>{errors[`urlName-${index}`]}</span>
                )
              }
              success={Boolean(url.name)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDInput
              fullWidth
              label="URL"
              type="text"
              value={url.URL}
              onChange={(e) =>
                handleProjectUrlChange(
                  values.projectUrls.map((u, i) =>
                    i === index ? { ...u, URL: e.target.value } : u
                  )
                )
              }
              error={false}
              helperText={
                errors[`url-${index}`] && (
                  <span style={{ color: "red" }}>{errors[`url-${index}`]}</span>
                )
              }
              success={Boolean(url.URL)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MDInput
              fullWidth
              label="Comments"
              type="text"
              value={url.projectComments}
              onChange={(e) =>
                handleProjectUrlChange(
                  values.projectUrls.map((u, i) =>
                    i === index ? { ...u, projectComments: e.target.value } : u
                  )
                )
              }
              error={false}
              helperText={
                errors[`projectComments-${index}`] && (
                  <span style={{ color: "red" }}>{errors[`projectComments-${index}`]}</span>
                )
              }
              success={Boolean(url.projectComments)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <MDButton
              variant="gradient"
              color="dark"
              style={{ minWidth: "50px", padding: "5px" }}
              onClick={addRowProjectUrl}
              iconOnly
            >
              <AddIcon />
            </MDButton>
            {index > 0 && (
              <MDButton
                variant="gradient"
                color="light"
                style={{ minWidth: "50px", marginLeft: "10px" }}
                onClick={() => removeRowProjectUrl(index)}
                iconOnly
              >
                <RemoveIcon />
              </MDButton>
            )}
          </Grid>
        </Grid>
      ))}
    </MDBox>
  );
}
// PropTypes validation
ProductInfo.propTypes = {
  values: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    clientName: PropTypes.string,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        fileName: PropTypes.string.isRequired,
      })
    ).isRequired,
    credentials: PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
      })
    ).isRequired,
    projectUrls: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        URL: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  handleCredentialChange: PropTypes.func.isRequired,
  handleProjectUrlChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  touched: PropTypes.object,
  errors: PropTypes.shape({
    projectName: PropTypes.string,
    projectId: PropTypes.string,
    clientName: PropTypes.string,
    fileName: PropTypes.string,
    comments: PropTypes.string,
    credName: PropTypes.string,
    environment: PropTypes.string,
    urlName: PropTypes.string,
    URL: PropTypes.string,
  }).isRequired,
  clientList: PropTypes.array.isRequired, // Define expected fields within errors
};
export default ProductInfo;
