import api from "../Environment.js";

class ClientAddService {
  getAll() {
    return api.get("/api/client");
  }
  add(data) {
    return api.post("/api/client", data);
  }
  getById(id) {
    return api.get(`/api/client/${id}`);
  }
  delete(id) {
    return api.delete(`/api/client/${id}`);
  }
  update(id, data) {
    return api.put(`/api/client/${id}`, data);
  }
}

export default new ClientAddService();
