import api from "../Environment.js";

class AddProjectService {
  add(data) {
    return api.post("api/project", data);
  }
  getAll() {
    return api.get("api/project");
  }
  getById(id) {
    return api.get(`api/project/${id}`);
  }
  update(id, data) {
    return api.put(`/api/project/${id}`, data);
  }
  delete(id) {
    return api.delete(`api/project/${id}`);
  }
}
export default new AddProjectService();
