/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import checkout, { clientForm } from "layouts/pages/users/new-user/schemas/form";
import checkout from "layouts/pages/users/new-user/schemas/form";

const {
  formField: { clientName, clientId, mobileNumber, email, type, field, value },
} = checkout;

const initialValues = {
  [clientName.name]: "",
  [clientId.name]: "",
  [mobileNumber.name]: "",
  [email.name]: "",
  [type.name]: "",
  [field.name]: "",
  [value.name]: "",
  projects: [],
  credential: [],
};

export default initialValues;
