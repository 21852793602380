/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import ClientAddService from "services/ClientAddService";
import MDButton from "components/MDButton";
import MySwal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const ActionIcons = ({ id, onDelete }) => {
  const [clientId, setUserId] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {}, [clientId]);

  const handleEditClick = () => {
    navigate("/client/clientAdd", { state: { clientId: id } });
  };

  const handleDelete = async (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#36454F",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#B2BEB5",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await ClientAddService.delete(id);
          if (res.status == 200) {
            MySwal.fire({
              title: "Deleted!",
              text: res.data ? res.data.message : "Client has been deleted.",
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Failed to delete client.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting client:", error);
          MySwal.fire({
            title: "Error!",
            text: "Failed to delete client.",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <>
      <MDButton
        variant="text"
        color="info"
        style={{ marginRight: "-25px" }}
        onClick={handleEditClick}
      >
        <Icon>edit</Icon>
      </MDButton>
      <MDButton variant="text" color="dark" style={{ marginRight: "-11px" }}>
        <Icon>visibility</Icon>
      </MDButton>
      <MDButton
        variant="text"
        color="error"
        style={{ marginLeft: "-17px" }}
        onClick={() => {
          handleDelete(id);
        }}
      >
        <Icon>delete</Icon>
      </MDButton>
    </>
  );
};

// ActionIcons.propTypes = {
//   id: PropTypes.string.isRequired,
//   onDelete: PropTypes.func.isRequired,
// };

const ActionCell = ({ row }) => {
  const { id } = row.original;
  return <ActionIcons id={id} />;
};

ActionCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const dataTableData = {
  columns: [
    {
      Header: "Client Name",
      accessor: "Clientname",
    },
    {
      Header: "Client ID",
      accessor: "clientId",
    },
    {
      Header: "Mobile Number",
      accessor: "MobileNumber",
    },
    {
      Header: () => <div style={{ textAlign: "left", paddingLeft: "55px" }}>Action</div>,
      accessor: "action",
      Cell: ({ row, onDelete }) => <ActionCell row={row} onDelete={onDelete} />,
      style: { textAlign: "left" },
    },
  ],
  rows: [],
  fetchData: async () => {
    try {
      const response = await ClientAddService.getAll();
      if (response && response.data) {
        const formattedData = response.data.map((client) => ({
          id: client._id,
          Clientname: client.clientName,
          clientId: client.clientId,
          MobileNumber: client.mobileNumber,
        }));
        return formattedData;
      }
      return [];
    } catch (error) {
      console.error("Error fetching client data:", error);
      return [];
    }
  },
};
export default dataTableData;
