/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import form from "layouts/pages/users/new-user/schemas/form";

// Destructure form fields
const { formField } = form;
const { clientName, clientId, email, mobileNumber } = formField;

// Create validation schema
const validations = Yup.object().shape({
  [clientName.name]: Yup.string()
    .required(clientName.errorMsg)
    .matches(/^[a-zA-Z\s]+$/, "Client Name must contain only letters and spaces"),

  [clientId.name]: Yup.string()
    .required(clientId.errorMsg)
    .matches(/^[a-zA-Z0-9]+$/, "Client ID must be alphanumeric"),

  [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),

  [mobileNumber.name]: Yup.string()
    .required(mobileNumber.errorMsg)
    .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits"),
});

export default validations;
