import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Autocomplete } from "@mui/material";

function ClientAdd({ formData, projectList }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { clientName, clientId, email, mobileNumber, projects, type, field, value } = formField;
  const {
    clientName: clientNameV,
    clientId: clientIdV,
    email: emailV,
    mobileNumber: mobileNumberV,
    projects: projectsV,
    credential: credentialV = [],
  } = values;

  const [rows, setRows] = useState(
    credentialV.length > 0 ? credentialV : [{ type: "", field: "", value: "" }]
  );

  useEffect(() => {
    if (credentialV.length > 0) {
      setRows(credentialV);
    }
  }, [credentialV]);

  const handleAddRow = () => {
    setRows([...rows, { type: "", field: "", value: "" }]);
  };

  const handleRemoveRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  return (
    <MDBox>
      <Grid container spacing={1}>
        <Grid item xs={6} paddingBottom={3}>
          <MDInput
            fullWidth
            type={clientName.type}
            label={clientName.label}
            name={clientName.name}
            value={clientNameV}
            onChange={(e) => setFieldValue(clientName.name, e.target.value)}
            error={false}
            helperText={
              errors.clientName && touched.clientName ? (
                <span style={{ color: "red" }}>{errors.clientName}</span>
              ) : (
                ""
              )
            }
            success={clientNameV.length > 0 && !errors.clientName}
          />
        </Grid>
        <Grid item xs={6}>
          <MDInput
            fullWidth
            type={clientId.type}
            label={clientId.label}
            name={clientId.name}
            value={clientIdV}
            onChange={(e) => setFieldValue(clientId.name, e.target.value)}
            error={false}
            helperText={
              errors.clientId && touched.clientId ? (
                <span style={{ color: "red" }}>{errors.clientId}</span>
              ) : (
                ""
              )
            }
            success={clientIdV.length > 0 && !errors.clientId}
          />
        </Grid>
        <Grid item xs={6}>
          <MDInput
            fullWidth
            type={email.type}
            label={email.label}
            name={email.name}
            value={emailV}
            onChange={(e) => setFieldValue(email.name, e.target.value)}
            error={false}
            helperText={
              errors.email && touched.email ? (
                <span style={{ color: "red" }}>{errors.email}</span>
              ) : (
                ""
              )
            }
            success={emailV.length > 0 && !errors.email}
          />
        </Grid>
        <Grid item xs={6} paddingBottom={4}>
          <MDInput
            fullWidth
            type={mobileNumber.type}
            label={mobileNumber.label}
            name={mobileNumber.name}
            value={mobileNumberV}
            onChange={(e) => setFieldValue(mobileNumber.name, e.target.value)}
            error={false}
            helperText={
              errors.mobileNumber && touched.mobileNumber ? (
                <span style={{ color: "red" }}>{errors.mobileNumber}</span>
              ) : (
                ""
              )
            }
            success={mobileNumberV.length > 0 && !errors.mobileNumber}
          />
        </Grid>
      </Grid>

      <MDTypography variant="h5">Project Name</MDTypography>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} paddingBottom={4}>
          <Autocomplete
            multiple
            options={projectList.map((project) => project.label)} // Assuming label is `projectName`
            getOptionLabel={(option) => option}
            value={values.projects || []}
            onChange={(event, newValue) => setFieldValue("projects", newValue)}
            renderInput={(params) => (
              <MDInput
                {...params}
                fullWidth
                label="Select Project"
                variant="outlined"
                error={errors.projects && touched.projects}
              />
            )}
          />
          {errors.projects && touched.projects && (
            <MDTypography variant="caption" color="error">
              {errors.projects}
            </MDTypography>
          )}
        </Grid>
      </Grid>

      <MDTypography variant="h5" paddingBottom={3}>
        Credential
      </MDTypography>
      {rows.map((row, index) => (
        <Grid container spacing={1} key={index}>
          <Grid item xs={3.5} mt={2} mr={2}>
            <MDInput
              fullWidth
              label="Type"
              value={row.type}
              onChange={(e) => {
                const updatedRows = [...rows];
                updatedRows[index].type = e.target.value;
                setRows(updatedRows);
                setFieldValue("credential", updatedRows);
              }}
            />
          </Grid>
          <Grid item xs={3} mt={2} mr={2}>
            <MDInput
              fullWidth
              label="Field"
              value={row.field}
              onChange={(e) => {
                const updatedRows = [...rows];
                updatedRows[index].field = e.target.value;
                setRows(updatedRows);
                setFieldValue("credential", updatedRows);
              }}
            />
          </Grid>
          <Grid item xs={3.8} mt={2}>
            <MDInput
              fullWidth
              label="Value"
              value={row.value}
              onChange={(e) => {
                const updatedRows = [...rows];
                updatedRows[index].value = e.target.value;
                setRows(updatedRows);
                setFieldValue("credential", updatedRows);
              }}
            />
          </Grid>
          <Grid item xs="auto" mt={2}>
            {index === 0 ? (
              <MDButton
                variant="gradient"
                color="dark"
                onClick={handleAddRow}
                style={{ minWidth: "50px", padding: "5px", marginRight: "4px" }}
              >
                <Icon fontSize="small">add</Icon>
              </MDButton>
            ) : (
              <>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleAddRow}
                  style={{ minWidth: "50px", padding: "5px", marginRight: "10px" }}
                >
                  <Icon fontSize="small">add</Icon>
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="light"
                  onClick={() => handleRemoveRow(index)}
                  style={{ minWidth: "50px", padding: "5px", marginRight: "4px" }}
                >
                  <Icon fontSize="small">remove</Icon>
                </MDButton>
              </>
            )}
          </Grid>
        </Grid>
      ))}
    </MDBox>
  );
}

ClientAdd.propTypes = {
  formData: PropTypes.shape({
    formField: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  projectList: PropTypes.array.isRequired,
};

export default ClientAdd;
