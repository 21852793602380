/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import AddProjectService from "services/AddProjectService";
import ClientAddService from "services/ClientAddService";
import MySwal from "sweetalert2"; // For confirmation alert
import { useNavigate } from "react-router-dom";

const dataTableData = {
  columns: [
    { Header: "Project Name", accessor: "projectName", width: "" },
    { Header: "Project ID", accessor: "projectId", align: "center", width: "" },
    { Header: "Client Name", accessor: "clientName", width: "" },
    {
      Header: <div style={{ textAlign: "left", paddingLeft: "25px" }}>Action</div>,
      accessor: "action",
      Cell: ({ row, onDelete }) => <ActionIcons id={row.original.id} onDelete={onDelete} />,
      style: { textAlign: "end" },
    },
  ],
  rows: [],

  fetchProjectList: async () => {
    try {
      const response = await AddProjectService.getAll();
      const clientResponse = await ClientAddService.getAll();
      const projectData = response?.data || [];
      const clientData = clientResponse?.data || [];
      const formattedData = projectData.map((project) => {
        const client = clientData.find((client) => client._id === project.clientId) || {};
        return {
          id: project._id,
          projectName: project.projectName,
          projectId: project.projectId,
          clientName: project.clientName || "Unknown",
          action: <ActionIcons id={project._id} />,
        };
      });

      return formattedData;
    } catch (error) {
      console.error("Error fetching project list data:", error);
      return [];
    }
  },
};

// ActionIcons component now accepts an `id` for the specific project and a function `onDelete` to delete it
function ActionIcons({ id, onDelete }) {
  const [projectId, setUserId] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {}, [projectId]);

  const handleEditClick = () => {
    navigate("/projects/add-project", { state: { projectId: id } });
  };
  const handleDelete = async () => {
    // Ask for confirmation before deleting
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#36454F",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#B2BEB5",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Call the delete API
          const res = await AddProjectService.delete(id);
          if (res.status === 200) {
            // On success, show success alert and call the onDelete to remove from table
            MySwal.fire({
              title: "Deleted!",
              text: res.data ? res.data.message : "Project has been deleted.",
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Failed to delete project.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting project:", error);
          MySwal.fire({
            title: "Error!",
            text: "Failed to delete project.",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <>
      <MDButton
        variant="text"
        color="info"
        style={{ marginRight: "-15px" }}
        onClick={handleEditClick}
      >
        <Icon>edit</Icon>
      </MDButton>
      <MDButton variant="text" color="error" style={{ marginLeft: "-17px" }} onClick={handleDelete}>
        <Icon>delete</Icon>
      </MDButton>
    </>
  );
}

export default dataTableData;
