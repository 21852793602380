/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-user-form",
  formField: {
    clientName: {
      name: "clientName",
      label: "Client Name",
      type: "text",
      errorMsg: " Please enter client Name",
    },
    clientId: {
      name: "clientId",
      label: "Client Id",
      type: "text",
      errorMsg: " Please enter client Id",
    },
    projects: {
      name: "projects",
      type: "text",
    },
    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: " Please enter Email address",
      invalidMsg: "Your email address is invalid",
    },
    mobileNumber: {
      name: "mobileNumber",
      label: "Mobile Number",
      type: "text",
      errorMsg: "Please enter Mobile Number",
      invalidMsg: "Your mobileNumber  is invalid",
    },
    type: {
      name: "type",
      label: "Type",
      type: "text",
      errorMsg: "type is required.",
    },
    field: {
      name: "field",
      label: "field",
      type: "text",
      errorMsg: "field is required.",
    },
    value: {
      name: "value",
      label: "value",
      type: "text",
      errorMsg: "value is required.",
    },
  },
};

export default form;
