/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

function Footer({ company, links }) {
  const { size } = typography;

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        Copyright &copy; {new Date().getFullYear()}
        <MDBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}></MDBox>
        <Link href={"#"} target="_blank">
          <MDTypography variant="button" fontWeight="medium">
            ASTA SPACE
          </MDTypography>
        </Link>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="flex-end"
        alignItems="end"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        Powered
        <MDBox color="text" mb={-0.5} mx={0.25}></MDBox>
        by&nbsp;
        <Link href={"https://astasystech.com/"} target="_blank">
          <MDTypography variant="button" fontWeight="medium" sx={{ marginLeft: 0.5 }}>
            ASTA SYSTECH
          </MDTypography>
        </Link>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
