import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Grid, Card } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import ClientAddService from "services/ClientAddService";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import form from "layouts/pages/users/new-user/schemas/form";
import validations from "layouts/pages/users/new-user/schemas/validations";
import AddProjectService from "services/AddProjectService";

function ClientAdd() {
  const { formId, formField } = form;
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state ? location.state.clientId : 0;
  const [initialData, setInitialData] = useState(initialValues);
  const [isEditMode, setIsEditMode] = useState(false);
  const [projectList, setProjectList] = useState([]);

  // State for success and error notification
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      fetchClientData();
    }
    fetchProjectList();
  }, [id]);

  const fetchClientData = async () => {
    try {
      const response = await ClientAddService.getById(id);
      const clientData = response.data;
      setInitialData({
        clientName: clientData.clientName,
        clientId: clientData.clientId,
        email: clientData.email,
        mobileNumber: clientData.mobileNumber,
        projects: clientData.projects.map((project) => project.projectName),
        credential: clientData.credential.map((cred) => {
          return {
            type: cred.type,
            field: cred.field,
            value: cred.value,
          };
        }),
      });
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await validations.validate(values, { abortEarly: false });
      const dataToSubmit = {
        clientName: values.clientName,
        clientId: values.clientId,
        email: values.email,
        mobileNumber: values.mobileNumber,
        projects: values.projects.map((projectName) => {
          const project = projectList.find((p) => p.value === projectName);
          return {
            projectId: project ? project.id : "",
            projectName: projectName,
          };
        }),
        credential: values.credential,
      };
      if (isEditMode) {
        await ClientAddService.update(id, dataToSubmit);
      } else {
        await ClientAddService.add(dataToSubmit);
      }
      openSuccessSB();
      setTimeout(() => {
        navigate("/client/clientList");
      }, 1000);
    } catch (error) {
      if (error.name === "ValidationError") {
        console.log("Validation failed", error.errors);
        openErrorSB();
      } else {
        setErrors({ submit: "Error submitting form. Please try again." });
        openErrorSB();
      }
    } finally {
      setSubmitting(false);
    }
  };

  const fetchProjectList = async () => {
    try {
      const response = await AddProjectService.getAll();
      const projects = response.data;
      const projectOptions = projects.map((project) => ({
        value: project.projectName,
        label: project.projectName,
        id: project._id,
        type: "project",
      }));
      setProjectList(projectOptions);
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={1} mb={3}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 4 }}>
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialData}
              onSubmit={handleSubmit}
              validationSchema={validations}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                setValues,
                setFieldValue,
                isSubmitting,
                validateForm,
              }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox p={4}>
                      <MDTypography variant="h5">
                        {isEditMode ? "Edit Client Info" : "Add Client Info"}
                      </MDTypography>
                      <MDBox mt={4}>
                        <UserInfo
                          formData={{
                            values,
                            setFieldValue,
                            setValues,
                            touched,
                            formField,
                            errors,
                          }}
                          projectList={projectList}
                        />
                        <MDBox mt={4} width="100%" display="flex" justifyContent="flex-end" gap={2}>
                          <MDButton
                            variant="gradient"
                            color="light"
                            onClick={() => setValues(initialValues)}
                          >
                            Clear
                          </MDButton>
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="dark"
                            disabled={isSubmitting}
                            onClick={async () => {
                              const formErrors = await validateForm();
                              if (Object.keys(formErrors).length > 0) {
                                openErrorSB();
                              }
                            }}
                          >
                            {isEditMode ? "Update" : "Submit"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>

      <MDSnackbar
        color="success"
        icon="check"
        title="Success"
        content={isEditMode ? "Client updated successfully!" : "Client added successfully!"}
        dateTime="justnow"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bggreen="true"
        sx={{
          position: "fixed",
          top: -500,
          right: 20,
          zIndex: 9999,
        }}
      />

      <MDSnackbar
        color="error"
        icon="warning"
        title="Error"
        content="Please fill all required fields."
        dateTime="justnow"
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgRed
        sx={{
          position: "fixed",
          top: -500,
          right: 20,
          zIndex: 9999,
        }}
      />

      <Footer />
    </DashboardLayout>
  );
}

export default ClientAdd;
