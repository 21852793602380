/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/ecommerce/products/product-page/data/dataTableData";

function ProjectPage() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  // Fetch the list of projects
  useEffect(() => {
    const fetchData = async () => {
      const data = await dataTableData.fetchProjectList();
      setRows(data);
    };
    fetchData();
  }, []);

  // Callback to remove a project from the table after deletion
  const handleDelete = (deletedId) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== deletedId));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={7}>
        <MDBox display="flex" justifyContent="flex-end" mb={2}>
          <MDButton
            variant="gradient"
            color="dark"
            onClick={() => navigate("/projects/add-project")}
          >
            Add Project
          </MDButton>
        </MDBox>
        <Card>
          <DataTable
            table={{ columns: dataTableData.columns, rows: rows }}
            entriesPerPage={false}
            canSearch
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProjectPage;
