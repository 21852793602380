import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData"; // Ensure it contains columns

function ClientList() {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dataTableData.fetchData();
        setRows(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={7}>
        <MDBox display="flex" justifyContent="flex-end" mb={2}>
          <MDButton variant="gradient" color="dark" onClick={() => navigate("/client/clientAdd")}>
            Add Client
          </MDButton>
        </MDBox>
        <Card>
          <DataTable
            table={{
              columns: dataTableData.columns,
              rows: rows,
            }}
            entriesPerPage={false}
            canSearch
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ClientList;

// import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import Card from "@mui/material/Card";
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
// import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData"; // Assuming it contains table columns

// function ClientList() {
//   const [rows, setRows] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const data = await dataTableData.fetchData(); // Your API call to fetch data
//         setRows(data); // Assuming data is an array of rows
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };
//     fetchData();
//   }, []);

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox my={3}>
//         <MDBox
//           display="flex"
//           justifyContent="space-between"
//           alignItems="flex-start"
//           mb={2}
//           ml={135}
//         >
//           <MDButton variant="gradient" color="dark" onClick={() => navigate("/client/clientAdd")}>
//             Add Client
//           </MDButton>
//         </MDBox>
//         <Card>
//           <DataTable
//             table={{
//               columns: dataTableData.columns, // Ensure columns are defined in dataTableData
//               rows: rows,
//             }}
//             entriesPerPage={false}
//             canSearch
//           />
//         </Card>
//       </MDBox>
//       <Footer />
//     </DashboardLayout>
//   );
// }

// export default ClientList;
